// src/firebase.js

import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// Firebase 설정 객체
const firebaseConfig = {
  apiKey: "AIzaSyCPRKk81nZXPqa5SyM4A8epGFbs1Zy8iJY",
  authDomain: "fareshare-7fa54.firebaseapp.com",
  projectId: "fareshare-7fa54",
  storageBucket: "fareshare-7fa54.appspot.com",
  messagingSenderId: "795548998124",
  appId: "1:795548998124:web:9456d82f4c06cb9753c8c9"
};

// Firebase 초기화
const app = initializeApp(firebaseConfig);

// Firebase 인증 및 Firestore 초기화
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
export const db = getFirestore(app);

// Firebase Cloud Messaging 초기화
export const messaging = getMessaging(app);

// Service Worker 등록
navigator.serviceWorker.register('/firebase-messaging-sw.js')
  .then((registration) => {
    console.log('Service Worker registered with scope:', registration.scope);
  }).catch((err) => {
    console.error('Service Worker registration failed:', err);
  });

// FCM 토큰을 받아오는 함수
export const getFcmToken = async () => {
  try {
    // FCM 토큰 요청
    const currentToken = await getToken(messaging, { vapidKey: "BPBph_N8CJIMnd0PRyCR7cxFMEEUptfnhRpQwvL8f1XtwBTxrhbRhTtiizXyg9GpJaDuPmjEXvqZVuslO-6n2BY" });
    
    if (currentToken) {
      console.log('FCM Token:', currentToken);
      return currentToken;
    } else {
      console.log('No registration token available. Request permission to generate one.');
      return null;
    }
  } catch (err) {
    console.error('An error occurred while retrieving token. ', err);
  }
};

// 메시지 수신 처리 (포그라운드 상태)
onMessage(messaging, (payload) => {
  console.log('Message received. ', payload);
  // 알림을 표시하거나 기타 작업을 수행할 수 있습니다.
});
