// src/pages/Login.js

import React, { useEffect } from "react";
import { auth, provider } from "../firebase";
import { signInWithPopup } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/authContext"; // useAuth 훅을 가져옵니다.

const Login = () => {
  const navigate = useNavigate();
  const { user } = useAuth(); // 현재 로그인된 사용자 정보 가져오기

  const signInWithGoogle = async () => {
    try {
      await signInWithPopup(auth, provider);
      navigate("/home");
    } catch (error) {
      console.error("Error signing in with Google", error);
    }
  };

  useEffect(() => {
    if (user) {
      navigate("/home"); // 사용자가 로그인되어 있으면 /home으로 리디렉션
    }
  }, [user, navigate]);

  return (
    <div style={{ textAlign: "center", padding: "20px" }}>
      <h1>Welcome to FareShare!</h1>
      <p>
        We're glad to have you here. FareShare is committed to making your 
        ride-sharing experience seamless and enjoyable. Please sign in to continue.
      </p>
      <h2>Login</h2>
      <button onClick={signInWithGoogle} style={{ padding: "10px 20px", fontSize: "16px" }}>
        Sign in with Google
      </button>
      <p style={{ marginTop: "20px" }}>
        By signing in, you agree to FareShare's Terms of Service and Privacy Policy.
      </p>
    </div>
  );
};

export default Login;
